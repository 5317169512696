import { configureStore } from "@reduxjs/toolkit";
import availableConfigReducer, {
  IAvailableConfigState,
} from "./configurator/available-config.slice";
import configuratorReducer, {
  IConfiguratorState,
} from "./configurator/configurator.slice";

export interface IState {
  configurator: IConfiguratorState;
  availableConfig: IAvailableConfigState;
}

const store = configureStore<IState>({
  reducer: {
    configurator: configuratorReducer,
    availableConfig: availableConfigReducer,
  },
  devTools: true,
});

export default store;
