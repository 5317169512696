import { IColor } from "../../src/models/style.model";

export const Colors: Array<IColor> = [
  {
    primary: "64, 160, 160",
    secondary: "55, 137, 137",
  },
  {
    primary: "204, 88, 9",
    secondary: "153, 66, 7",
  },

  {
    primary: "255, 184, 0",
    secondary: "204, 147, 0",
  },
];

export default Colors;
