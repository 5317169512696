// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".MLwRBHOvTSZjOWw1Nmlc {\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/hint/hint.module.css"],"names":[],"mappings":"AAAA;AACA","sourcesContent":[".container {\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "MLwRBHOvTSZjOWw1Nmlc"
};
export default ___CSS_LOADER_EXPORT___;
