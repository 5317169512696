import { getComponentId } from "../../../util/util";
import Backdrop from "../backdrop/backdrop.component";
import ConditionalWrapper from "../conditional-wrapper/conditional-wrapper.component";
import AlertViewFooter from "./alert-view-footer/alert-view-footer.component";

import styles from "./alert-view.module.css";

export interface IAlertViewProps {
  id?: string;
  show?: boolean;
  leftButtonLabel?: string;
  rightButtonLabel?: string;
  onLeftButtonClick?: () => void;
  onRightButtonClick?: () => void;
  isLeftButtonDisabled?: boolean;
  isRightButtonDisabled?: boolean;
  hasBackdrop?: boolean;
  backdropClassName?: string;
  children?: JSX.Element | Array<JSX.Element>;
}

const AlertView = ({
  id,
  show,
  leftButtonLabel,
  rightButtonLabel,
  onLeftButtonClick,
  onRightButtonClick,
  isLeftButtonDisabled,
  isRightButtonDisabled,
  hasBackdrop = true,
  backdropClassName,
  children,
}: IAlertViewProps): JSX.Element => {
  id = getComponentId("alertView", id);

  return (
    <ConditionalWrapper
      id={id}
      when={hasBackdrop}
      render={({ id, children }) => (
        <Backdrop
          id={id}
          show={show}
          flexCenter
          fixed
          className={backdropClassName}
        >
          {children}
        </Backdrop>
      )}
    >
      <>
        {show && (
          <div data-testid={`${id}-container`} className={styles.container}>
            <div data-testid={`${id}-content`} className={styles.content}>
              {children && children}
            </div>
            <AlertViewFooter
              {...{
                id,
                leftButtonLabel,
                rightButtonLabel,
                onLeftButtonClick,
                onRightButtonClick,
                isLeftButtonDisabled,
                isRightButtonDisabled,
              }}
            />
          </div>
        )}
      </>
    </ConditionalWrapper>
  );
};

export default AlertView;
