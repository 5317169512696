import { combineClassNames, getComponentId } from "../../../util/util";
import styles from "./backdrop.module.css";

export interface IBackdropProps {
  id?: string;
  show?: boolean;
  flexCenter?: boolean;
  className?: string;
  onClick?: () => void;
  children: JSX.Element | Array<JSX.Element>;
  fixed?: boolean;
}

const Backdrop = ({
  id,
  show,
  flexCenter,
  fixed,
  className,
  onClick,
  children,
}: IBackdropProps): JSX.Element | null => {
  id = getComponentId("backdrop", id);

  const containerStyles = combineClassNames(
    styles.container,
    className,
    flexCenter ? styles.center : null,
    fixed ? styles.fixed : null
  );

  if (!show) return null;

  return (
    <div
      data-testid={`${id}-container`}
      className={containerStyles}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Backdrop;
