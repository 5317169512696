import React from "react";
import SVG from "react-inlinesvg";
import { getComponentId, combineClassNames } from "../../../../util/util";
import styles from "./selection-card-text.module.css";

interface SelectionCardTextProps {
  id?: string;
  className?: string;
  icon?: string;
  title: string;
  body?: string;
  selected?: boolean;
}

const SelectionCardText = ({
  id,
  className,
  icon,
  title,
  body,
  selected,
}: SelectionCardTextProps): JSX.Element => {
  id = getComponentId("selectionCardText", id);

  return (
    <div
      id={`${id}-container`}
      className={combineClassNames(
        styles.container,
        className,
        selected ? styles.selected : null
      )}
    >
      <div id={`${id}-header`} className={styles.header}>
        {icon && (
          <div id={`${id}-iconContainer`} className={styles.iconContainer}>
            <SVG src={icon} id={`${id}-icon`} className={styles.icon} />
          </div>
        )}
        <h3 id={`${id}-title`} className={styles.title}>
          {title}
        </h3>
      </div>
      <p id={`${id}-body`} className={styles.body}>
        <span className="secondary">{body}</span>
      </p>
    </div>
  );
};

export default SelectionCardText;
