import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import "./App.css";
import ConfiguratorPage from "./pages/configuratior-page/configurator-page.component";

const App = (): JSX.Element => {
  return (
    <Router>
      <Switch>
        <Route exact path={"/"} component={ConfiguratorPage} />
      </Switch>
    </Router>
  );
};

export default App;
