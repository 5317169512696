import { ISelectableCategory } from "../../src/models/models";
import Colors from "../configuration/color";

export const combineClassNames = (
  ...classNames: Array<string | undefined | null>
): string => [...classNames].filter((className) => className != null).join(" ");

export const getComponentId = (name: string, id?: string): string =>
  id ? `${id}-${name}` : `${name}`;

export const snakeToTitleCase = (value: string): string =>
  value
    .split("_")
    .map((word) => [word[0].toUpperCase(), word.substring(1)].join(""))
    .join(" ");

export const snakeToPascalCase = (value: string): string =>
  value
    .split("_")
    .map((word) => [word[0].toUpperCase(), word.substring(1)].join(""))
    .join("");

export const getPrimaryColor = (index: number): string => {
  return Colors[index].primary;
};

export const getSecondaryColor = (index: number): string =>
  Colors[index].secondary;

export const getTotalWeight = (
  categories: Array<ISelectableCategory>
): number =>
  categories
    .filter((category) => category.selected)
    .map((selectedCategory) => selectedCategory.skills)
    .flat()
    .filter((skill) => skill.selected)
    .map((skill) => skill.weight)
    .reduce((acc, weight) => acc + weight, 0);
