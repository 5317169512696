// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".nDDDzFgoDA78kTQqBuBL {\n  margin: 36px 0;\n  overflow: visible;\n  width: 60vw;\n  height: 75vh;\n  display: flex;\n  flex-direction: column;\n  gap: 36px;\n}\n\n.KJP9SjvhjXgte_aDd9Nl {\n  flex: 1;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.sFbNiU9UaQGODbvHQU50 {\n  background-color: white;\n  border: 1px solid #e6e6e6;\n  border-radius: 12px;\n  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);\n  width: 100%;\n  flex: 1;\n  position: relative;\n  overflow: hidden;\n}\n\n.LsvtsxC17weHJgaw_zwX {\n  position: absolute;\n  bottom: 12px;\n  right: 15px;\n  background-color: white !important;\n  transition: background-color 0.2s ease;\n}\n\n.mer3Zdsg1TUj8dnqNxwZ {\n  background-color: #c5ffcd !important;\n}\n", "",{"version":3,"sources":["webpack://./src/components/code-preview/code-preview.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,iBAAiB;EACjB,WAAW;EACX,YAAY;EACZ,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,OAAO;EACP,WAAW;EACX,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,uBAAuB;EACvB,yBAAyB;EACzB,mBAAmB;EACnB,2CAA2C;EAC3C,WAAW;EACX,OAAO;EACP,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,kCAAkC;EAClC,sCAAsC;AACxC;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[".container {\n  margin: 36px 0;\n  overflow: visible;\n  width: 60vw;\n  height: 75vh;\n  display: flex;\n  flex-direction: column;\n  gap: 36px;\n}\n\n.sectionContainer {\n  flex: 1;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.section {\n  background-color: white;\n  border: 1px solid #e6e6e6;\n  border-radius: 12px;\n  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);\n  width: 100%;\n  flex: 1;\n  position: relative;\n  overflow: hidden;\n}\n\n.copyButton {\n  position: absolute;\n  bottom: 12px;\n  right: 15px;\n  background-color: white !important;\n  transition: background-color 0.2s ease;\n}\n\n.copied {\n  background-color: #c5ffcd !important;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "nDDDzFgoDA78kTQqBuBL",
	"sectionContainer": "KJP9SjvhjXgte_aDd9Nl",
	"section": "sFbNiU9UaQGODbvHQU50",
	"copyButton": "LsvtsxC17weHJgaw_zwX",
	"copied": "mer3Zdsg1TUj8dnqNxwZ"
};
export default ___CSS_LOADER_EXPORT___;
