import SVG from "react-inlinesvg";
import { getComponentId, combineClassNames } from "../../../../util/util";
import InputSlider, {
  IInputSliderProps,
} from "../../inputs/input-slider/input-slider.component";

import styles from "./selection-card-slider.module.css";

export interface ISelectionCardSlider
  extends Omit<IInputSliderProps, "className" | "light"> {
  id?: string;
  title: string;
  valueOf: number;
  className?: string;
  selected?: boolean;
  icon: any;
}

const SelectionCardSlider = ({
  id,
  title,
  maxValue,
  minValue,
  onChange,
  value,
  valueOf,
  className,
  showLabels,
  selected,
  icon,
}: ISelectionCardSlider): JSX.Element => {
  id = getComponentId("selectionCardSlider", id);

  const getPercent = () => {
    const percent = Math.round((value / valueOf) * 100);
    return isNaN(percent) ? 0 : percent;
  };

  return (
    <div
      id={`${id}-container`}
      className={combineClassNames(
        styles.container,
        className,
        selected ? styles.selected : null
      )}
    >
      <div id={`${id}-header`} className={styles.header}>
        {icon && selected && (
          <div id={`${id}-iconContainer`} className={styles.iconContainer}>
            <SVG src={icon} id={`${id}-icon`} className={styles.icon} />
          </div>
        )}
        <h3
          id={`${id}-title`}
          className={styles.title}
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p className={styles.hint} id={`${id}-percent`}>{`${getPercent()}%`}</p>
      </div>
      <InputSlider
        maxValue={maxValue}
        minValue={minValue}
        onChange={onChange}
        value={value}
        showLabels={showLabels}
        light={selected}
        className={styles.slider}
        disabled={!selected}
      />
    </div>
  );
};

export default SelectionCardSlider;
