import { IWeigtedSkill } from "../models/models";
import { snakeToPascalCase } from "../util/util";

interface IOptions {
  weigtedSkills: Array<IWeigtedSkill>;
  competence: string;
}

const getSkills = (skills: Array<IWeigtedSkill>): string => {
  if (!skills.length) return "{}";
  return [
    skills
      .map((skill) =>
        [
          `\t\tnew SkillInstance {`,
          `\t\t\tSkillId = new Guid("${skill.skillId}"),`,
          `\t\t\tWeight = ${skill.weight},`,
          `\t\t},`,
        ].join("\n")
      )
      .join("\n"),
  ].join("\n");
};

export const getCategorizationCode = ({
  weigtedSkills: skills,
  competence,
}: IOptions): string =>
  [
    `Categorization categorization = new Categorization {`,
    `\tCompetenceLevel = CompetenceLevel.${snakeToPascalCase(competence)},`,
    `\tSkillInstances = new SkillInstance[] {`,
    `${getSkills(skills)}`,
    `\t},`,
    `},`,
  ].join("\n");
