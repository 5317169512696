import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import competences from "../../assets/in/competences.json";
import curricula from "../../assets/in/curricula.json";
import { ICurriculum } from "../../../src/models/models";

export interface IAvailableConfigState {
  competences: null | Array<string>;
  isCompetencesLoading: boolean;
  competencesError: null | string;
  curriculaConfiguration: null | Array<ICurriculum>;
  isCurriculaConfigurationLoading: boolean;
  curriculaConfigurationError: null | string;
}

const initialState: IAvailableConfigState = {
  competences: null,
  isCompetencesLoading: false,
  competencesError: null,
  curriculaConfiguration: null,
  isCurriculaConfigurationLoading: false,
  curriculaConfigurationError: null,
};

export const fetchCompetences = createAsyncThunk(
  "configurator/fetchCompetences",
  () => competences
);

export const fetchCurricula = createAsyncThunk(
  "configurator/fetchCurricula",
  () => curricula
);

export const configuratorSlice = createSlice({
  name: "configurator",
  initialState,
  reducers: {
    setCompetences: (
      state,
      action: PayloadAction<IAvailableConfigState["competences"]>
    ) => {
      state.competences = action.payload;
    },
  },
  extraReducers: {
    [fetchCompetences.pending.type]: (state) => {
      state.competences = null;
      state.isCompetencesLoading = true;
      state.competencesError = null;
    },
    [fetchCompetences.fulfilled.type]: (state, action) => {
      state.competences = action.payload;
      state.isCompetencesLoading = false;
      state.competencesError = null;
    },
    [fetchCompetences.rejected.type]: (state, action) => {
      state.competences = null;
      state.isCompetencesLoading = false;
      state.competencesError = action.payload;
    },
    [fetchCurricula.pending.type]: (state) => {
      state.curriculaConfiguration = null;
      state.isCurriculaConfigurationLoading = true;
      state.curriculaConfigurationError = null;
    },
    [fetchCurricula.fulfilled.type]: (state, action) => {
      state.curriculaConfiguration = action.payload;
      state.isCurriculaConfigurationLoading = false;
      state.curriculaConfigurationError = null;
    },
    [fetchCurricula.rejected.type]: (state, action) => {
      state.curriculaConfiguration = null;
      state.isCurriculaConfigurationLoading = false;
      state.curriculaConfigurationError = action.payload;
    },
  },
});

export const { setCompetences } = configuratorSlice.actions;

export default configuratorSlice.reducer;
