import { CSSProperties } from "react";
import { getComponentId } from "../../../../util/util";

import styles from "./alert-view-footer.module.css";

interface AlertViewFooterProps {
  id?: string;
  leftButtonLabel?: string;
  rightButtonLabel?: string;
  onLeftButtonClick?: () => void;
  onRightButtonClick?: () => void;
  isLeftButtonDisabled?: boolean;
  isRightButtonDisabled?: boolean;
  rightButtonColor?: CSSProperties["color"];
}

const AlertViewFooter = ({
  id,
  leftButtonLabel,
  rightButtonLabel,
  onLeftButtonClick,
  onRightButtonClick,
  isLeftButtonDisabled,
  isRightButtonDisabled,
}: AlertViewFooterProps): JSX.Element => {
  id = getComponentId("alertViewFooter", id);

  return (
    <div data-testid={`${id}-container`} className={styles.container}>
      {leftButtonLabel && (
        <button
          data-testid={`${id}-button-left`}
          className={styles.button}
          onClick={onLeftButtonClick}
          disabled={isLeftButtonDisabled || !onLeftButtonClick}
        >
          {leftButtonLabel}
        </button>
      )}
      {rightButtonLabel && (
        <button
          data-testid={`${id}-button-right`}
          className={styles.button}
          onClick={onRightButtonClick}
          disabled={isRightButtonDisabled || !onRightButtonClick}
        >
          {rightButtonLabel}
        </button>
      )}
    </div>
  );
};

export default AlertViewFooter;
