import { useRef } from "react";
import Scrollbar from "react-scrollbars-custom";
import { ISelectableCategory, ISelectableSkill } from "../../models/models";
import {
  getComponentId,
  combineClassNames,
  snakeToTitleCase,
} from "../../util/util";
import SelectionCardSlider from "../common/selection-card/selection-card-slider/selection-card-slider.component";
import SelectionCardText from "../common/selection-card/selection-card-text/selection-card-text.component";
import SelectionCard from "../common/selection-card/selection-card.component";

import styles from "./skill-configurator.module.css";

import icon from "../../assets/icons/checkbox.svg";

export interface ISkillConfigurator {
  id?: string;
  className?: string;
  categories: Array<ISelectableCategory>;
  onCategoryClick: (id: string) => void;
  onSkillClick: (skillId: string) => void;
  onSkillChange: (skillId: string, value: number) => void;
  totalWeight: number;
}

const SkillConfigurator = ({
  id,
  className,
  categories,
  onCategoryClick,
  onSkillClick,
  onSkillChange,
  totalWeight,
}: ISkillConfigurator): JSX.Element => {
  id = getComponentId("focusConfigurator", id);

  const containerClassName = combineClassNames(className, styles.container);

  const selectedCategories = categories.filter((category) => category.selected);
  const skills = selectedCategories
    .map((selectedCategory) => selectedCategory.skills)
    .flat();

  const focusRef = useRef(null);

  const categoryCount = categories.length;
  const categoryHeight = Math.min(categoryCount * 105, 667);
  const skillCount = skills.length;
  const skillHeight = Math.min(skillCount * 136, 667);

  const getCategoryIndexBySkill = (
    categories: Array<ISelectableCategory>,
    skill: ISelectableSkill
  ): number =>
    categories.findIndex((category) => category.skills.indexOf(skill) !== -1);

  const getCategoryBySkill = (
    categories: Array<ISelectableCategory>,
    skill: ISelectableSkill
  ): ISelectableCategory | undefined =>
    categories.find((category) => category.skills.indexOf(skill) !== -1);

  return (
    <div data-testid={`${id}-container`} className={containerClassName}>
      <div data-testid={`${id}-focus-column`} className={styles.focusColumn}>
        <Scrollbar style={{ height: `${categoryHeight}px` }}>
          {categories.map((category, index) => (
            <SelectionCard
              key={category.categoryId}
              selected={category.selected}
              hasChevron
              className={styles.focusCard}
              onClick={() => onCategoryClick(category.categoryId)}
              forwardRef={index === 0 ? focusRef : undefined}
              index={index}
            >
              <SelectionCardText
                id={id}
                title={snakeToTitleCase(category.name)}
                body={`${category.skills.length} topics`}
                selected={category.selected}
              />
            </SelectionCard>
          ))}
        </Scrollbar>
      </div>
      <div data-testid={`${id}-topic-column`} className={styles.topicColumn}>
        {!!skills.length && (
          <Scrollbar style={{ height: `${skillHeight}px` }}>
            {skills.map((skill) => (
              <SelectionCard
                key={skill.skillId}
                className={styles.topicCard}
                onClick={() => onSkillClick(skill.skillId)}
                selected={skill.selected}
                index={getCategoryIndexBySkill(categories, skill)}
              >
                <SelectionCardSlider
                  icon={icon}
                  maxValue={100}
                  minValue={0}
                  onChange={(value) => onSkillChange(skill.skillId, value)}
                  title={`${getCategoryBySkill(
                    categories,
                    skill
                  )?.name[0].toUpperCase()}: ${skill.name}`}
                  value={skill.weight}
                  selected={skill.selected}
                  valueOf={totalWeight as any}
                />
              </SelectionCard>
            ))}
          </Scrollbar>
        )}
      </div>
    </div>
  );
};

export default SkillConfigurator;
