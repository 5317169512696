// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._9HXRK4GNLVSeLCieA_Yg {\n  background-color: rgba(0, 0, 0, 0.2) !important;\n  z-index: 10;\n}\n\n.HLx0R9iO9AlaMjw6T3p_ {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.IIdjNZ65qO4vNMtqwd1y {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/backdrop/backdrop.module.css"],"names":[],"mappings":"AAAA;EACE,+CAA+C;EAC/C,WAAW;AACb;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,MAAM;EACN,SAAS;EACT,OAAO;EACP,QAAQ;AACV","sourcesContent":[".container {\n  background-color: rgba(0, 0, 0, 0.2) !important;\n  z-index: 10;\n}\n\n.center {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n}\n\n.fixed {\n  position: fixed;\n  top: 0;\n  bottom: 0;\n  left: 0;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "_9HXRK4GNLVSeLCieA_Yg",
	"center": "HLx0R9iO9AlaMjw6T3p_",
	"fixed": "IIdjNZ65qO4vNMtqwd1y"
};
export default ___CSS_LOADER_EXPORT___;
