// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".uLoQu9FmF5G_W65pQT9P {\n  background-color: white;\n  display: flex;\n  flex-direction: column;\n}\n\n.uLoQu9FmF5G_W65pQT9P {\n  border-radius: 0 0 12px 12px;\n}\n\n.uLoQu9FmF5G_W65pQT9P.b5m_HTx_RVi5xrPZ80cF {\n  width: 400px;\n  min-height: 310px;\n}\n\n.uLoQu9FmF5G_W65pQT9P._77irNxJafAfBXA_30UjQ {\n  width: 660px;\n  min-height: 500px;\n}\n\n._mU7h1nwoBzMqF9J_gmz {\n  flex: 1;\n  background-color: white;\n  padding: 0 36px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/alert-view/alert-view.module.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,4BAA4B;AAC9B;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB;;AAEA;EACE,OAAO;EACP,uBAAuB;EACvB,eAAe;AACjB","sourcesContent":[".container {\n  background-color: white;\n  display: flex;\n  flex-direction: column;\n}\n\n.container {\n  border-radius: 0 0 12px 12px;\n}\n\n.container.small {\n  width: 400px;\n  min-height: 310px;\n}\n\n.container.big {\n  width: 660px;\n  min-height: 500px;\n}\n\n.content {\n  flex: 1;\n  background-color: white;\n  padding: 0 36px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "uLoQu9FmF5G_W65pQT9P",
	"small": "b5m_HTx_RVi5xrPZ80cF",
	"big": "_77irNxJafAfBXA_30UjQ",
	"content": "_mU7h1nwoBzMqF9J_gmz"
};
export default ___CSS_LOADER_EXPORT___;
