// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".wTr10F2LEpB8uObuBtOi {\n  background-color: #e7f0f7;\n  padding-top: 6.6vh;\n  min-height: 100vh;\n  display: flex;\n}\n\n.z9Jby2IiIAkuHNBdowl3 {\n  flex: 1;\n  padding: 25px 39px 29px 36px;\n  max-width: min(100%, 1200px);\n  margin: 0 auto;\n}\n\n.R3gNE3wrJIymDDFCPQM4 {\n  margin-bottom: 36px;\n}\n\n.EL7ISWhEfnEGW8st69DW {\n  margin-bottom: 46px;\n}\n", "",{"version":3,"sources":["webpack://./src/pages/configuratior-page/configurator-page.module.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,iBAAiB;EACjB,aAAa;AACf;;AAEA;EACE,OAAO;EACP,4BAA4B;EAC5B,4BAA4B;EAC5B,cAAc;AAChB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".container {\n  background-color: #e7f0f7;\n  padding-top: 6.6vh;\n  min-height: 100vh;\n  display: flex;\n}\n\n.safeArea {\n  flex: 1;\n  padding: 25px 39px 29px 36px;\n  max-width: min(100%, 1200px);\n  margin: 0 auto;\n}\n\n.iconHeader {\n  margin-bottom: 36px;\n}\n\n.description {\n  margin-bottom: 46px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "wTr10F2LEpB8uObuBtOi",
	"safeArea": "z9Jby2IiIAkuHNBdowl3",
	"iconHeader": "R3gNE3wrJIymDDFCPQM4",
	"description": "EL7ISWhEfnEGW8st69DW"
};
export default ___CSS_LOADER_EXPORT___;
