import { getComponentId, combineClassNames } from "../../../../util/util";
import styles from "./form-section.module.css";

export interface IFormSectionProps {
  id?: string;
  className?: string;
  children?: JSX.Element | Array<JSX.Element>;
}

const FormSection = ({
  id,
  className,
  children,
}: IFormSectionProps): JSX.Element => {
  id = getComponentId("formSection", id);

  const containerClassName = combineClassNames(className, styles.container);

  return (
    <div data-testid={`${id}-container`} className={containerClassName}>
      {children}
    </div>
  );
};

export default FormSection;
