import { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import FormSection from "../../components/common/form/form-section/form-section.component";
import SelectInput from "../../components/common/inputs/select-input/select-input.component";
import SkillConfigurator from "../../components/skill-configurator/skill-configurator.component";
import {
  fetchCurricula,
  fetchCompetences,
} from "../../redux/configurator/available-config.slice";
import {
  setSelectedCurriculumId,
  setCompetence,
  setCurricula,
  setCategories,
  selectCategory,
  selectSkill,
  changeSkill,
  filterSkill,
} from "../../redux/configurator/configurator.slice";
import { IState } from "../../redux/store";
import {
  getComponentId,
  combineClassNames,
  snakeToTitleCase,
  getTotalWeight,
} from "../../util/util";
import styles from "./configurator.module.css";
import PrimaryButton from "../../components/common/inputs/buttons/primary-button/primary-button.component";
import { Popup } from "semantic-ui-react";
import fuzzy from "fuzzy";
import SVG from "react-inlinesvg";

import icon from "../../assets/icons/send.svg";
import cross from "../../assets/icons/cross.svg";

export interface IConfiguratorProps {
  id?: string;
  className?: string;
  onConfirm: () => void;
  isSubmitDisabled?: boolean;
}

const Configurator = ({
  id,
  className,
  onConfirm,
  isSubmitDisabled,
}: IConfiguratorProps): JSX.Element => {
  id = getComponentId("configurator", id);

  const dispatch = useDispatch();

  const curriculumConfiguration = useSelector(
    (state: IState) => state.availableConfig.curriculaConfiguration
  );

  const competences = useSelector(
    (state: IState) => state.availableConfig.competences
  );

  const categories = useSelector(
    (state: IState) => state.configurator.categories
  );

  const totalWeight: number = useSelector((state: IState) =>
    getTotalWeight(state.configurator.categories)
  );

  const curricula = useSelector(
    (state: IState) => state.configurator.curricula
  );

  const selectedCurriculumId = useSelector(
    (state: IState) => state.configurator.selectedCurriculumId
  );

  const skillFilter = useSelector(
    (state: IState) => state.configurator.skillFilter
  );

  const containerClassName = combineClassNames(className, styles.container);

  useEffect(() => {
    batch(() => {
      dispatch(fetchCompetences());
      dispatch(fetchCurricula());
    });
  }, [dispatch]);

  useEffect(() => {
    if (!curriculumConfiguration) return;
    dispatch(setCurricula(curriculumConfiguration));
  }, [dispatch, curriculumConfiguration]);

  useEffect(() => {
    if (
      !curricula ||
      !curricula.find(
        (curriculum) => curriculum.CurriculumId === selectedCurriculumId
      )
    )
      return;

    const selectedCurriculum = curricula?.find(
      (curriculum) => curriculum.CurriculumId === selectedCurriculumId
    );
    if (!selectedCurriculum) return;
    dispatch(setCategories(selectedCurriculum));
  }, [dispatch, curricula, selectedCurriculumId]);

  useEffect(() => {
    batch(() => {
      dispatch(setCompetence(competences?.[0] ?? null));
    });
  }, [dispatch, competences]);

  const handleCompetenceChange = (value: string) =>
    dispatch(setCompetence(value));

  const handleCorriculumChange = (id: string) =>
    dispatch(setSelectedCurriculumId(id));

  const handleCategoryClick = (id: string) => {
    dispatch(selectCategory(id));
  };

  const handleSkillClick = (skillId: string) => {
    dispatch(selectSkill(skillId));
  };

  const handleSkillChange = (skillId: string, value: number) => {
    dispatch(changeSkill({ skillId, value }));
  };

  const [filteredCategories, setFilteredCategories] = useState(categories);

  const handleFilterChange: React.ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    const filter = event.target.value || null;
    dispatch(filterSkill(filter));
  };

  useEffect(() => {
    const filterCategories = () => {
      if (!skillFilter) return categories;
      const visibleSkills = categories.flatMap((category) =>
        category.skills.map((skill) => skill.name)
      );
      console.debug({ visibleSkills });
      const matches = fuzzy.filter(skillFilter ?? "", visibleSkills, {
        pre: "<b>",
        post: "</b>",
      });
      const newCategories = categories.map((category) => {
        return {
          ...category,
          skills: category.skills
            .filter((skill) =>
              matches.some((match) => match.original === skill.name)
            )
            .map((skill) => ({
              ...skill,
              name:
                matches.find((match) => match.original === skill.name)
                  ?.string ?? "",
            })),
        };
      });
      return newCategories;
    };
    setFilteredCategories(filterCategories());
  }, [categories, skillFilter]);

  const handleFilterReset = () => {
    dispatch(filterSkill(null));
  };

  return (
    <div className={containerClassName} data-testid={`${id}-container`}>
      <FormSection
        className={combineClassNames(styles.formSection, styles.focusSection)}
      >
        <SelectInput
          options={curricula.map((corriculum) => ({
            text: corriculum.Name,
            value: corriculum.CurriculumId,
          }))}
          className={styles.corriculum}
          placeholder="Curriculum"
          onChange={handleCorriculumChange}
          label="Curriculum"
        />
        <div className={styles.inputForm}>
          <span className={styles.text}>Schritt 1: Kompetenzniveau</span>
          <span className={styles.text}>Schritt 2: Fokus</span>
          <div className={styles.textWrapper}>
            <span className={styles.text}>Schritt 3: Skills</span>
            <input
              type="text"
              onChange={handleFilterChange}
              value={skillFilter ?? ""}
              className={styles.input}
              placeholder="Filter"
            />
            <button
              className={styles.iconWrapper}
              disabled={!skillFilter}
              onClick={handleFilterReset}
            >
              <SVG src={cross} />
            </button>
          </div>
          {competences && (
            <SelectInput
              options={competences.map((competence) => ({
                text: snakeToTitleCase(competence),
                value: competence,
              }))}
              className={styles.competenceInput}
              placeholder="Level"
              onChange={handleCompetenceChange}
              defaultValue={competences.at(0)}
            />
          )}
          <SkillConfigurator
            categories={filteredCategories}
            onCategoryClick={handleCategoryClick}
            onSkillClick={handleSkillClick}
            onSkillChange={handleSkillChange}
            totalWeight={totalWeight}
            className={styles.focusInput}
          />
        </div>
        <Popup
          trigger={
            <div className={styles.confirmButton}>
              <PrimaryButton
                onClick={onConfirm}
                type="submit"
                buttonIcon={icon}
                buttonText="Code Generieren"
                disabled={isSubmitDisabled}
              />
            </div>
          }
          on="hover"
          content="Die aktuelle Konfiguration ist nicht gültig!"
          position="top right"
          disabled={!isSubmitDisabled}
        />
      </FormSection>
    </div>
  );
};

export default Configurator;
