// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HsUxI7sNJaHi2RpZYEjL {\n  display: flex;\n  align-items: center;\n  height: 26px;\n}\n\n.Jrw23E9bxVISYb3hexPA {\n  --icon-size: 26px;\n\n  width: var(--icon-size);\n  min-width: var(--icon-size);\n  height: var(--icon-size);\n  min-height: var(--icon-size);\n  margin-right: 8px;\n  fill: rgb(var(--text-primary));\n}\n\n.t3KkNu7BbOdqNfjGY1dC {\n  margin: 0;\n  font-size: 22px;\n  font-weight: normal;\n  color: rgb(var(--text-primary));\n  font-family: \"Source Sans Pro\";\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/icon-header/icon-header.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE,iBAAiB;;EAEjB,uBAAuB;EACvB,2BAA2B;EAC3B,wBAAwB;EACxB,4BAA4B;EAC5B,iBAAiB;EACjB,8BAA8B;AAChC;;AAEA;EACE,SAAS;EACT,eAAe;EACf,mBAAmB;EACnB,+BAA+B;EAC/B,8BAA8B;AAChC","sourcesContent":[".container {\n  display: flex;\n  align-items: center;\n  height: 26px;\n}\n\n.icon {\n  --icon-size: 26px;\n\n  width: var(--icon-size);\n  min-width: var(--icon-size);\n  height: var(--icon-size);\n  min-height: var(--icon-size);\n  margin-right: 8px;\n  fill: rgb(var(--text-primary));\n}\n\n.header {\n  margin: 0;\n  font-size: 22px;\n  font-weight: normal;\n  color: rgb(var(--text-primary));\n  font-family: \"Source Sans Pro\";\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "HsUxI7sNJaHi2RpZYEjL",
	"icon": "Jrw23E9bxVISYb3hexPA",
	"header": "t3KkNu7BbOdqNfjGY1dC"
};
export default ___CSS_LOADER_EXPORT___;
