// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DjMolANpv_CNvL9BlsGr {\n  display: flex;\n  flex-direction: row;\n  column-gap: 32px;\n  margin-bottom: 64px;\n}\n\n.GLvezbh6mLnTZg9YH9pa {\n  width: 212px;\n}\n\n.Zn_1v6gyuG_SIzTOtKi9 {\n  width: 390px;\n  position: relative;\n}\n\n.MXrQ4IFDGZt6Wd_zWHiF {\n  height: 50%;\n}\n\n.NGwojTNRPzg9q51NnPIe {\n  width: 185px;\n}\n\n.ODDJgNDFL745Xn6Rk2Y9 {\n  width: 340px;\n}\n\n.zpGytKst18OFPlUpG6Y9 {\n  margin-bottom: 16px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/skill-configurator/skill-configurator.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: row;\n  column-gap: 32px;\n  margin-bottom: 64px;\n}\n\n.focusColumn {\n  width: 212px;\n}\n\n.topicColumn {\n  width: 390px;\n  position: relative;\n}\n\n.scrollbar {\n  height: 50%;\n}\n\n.focusCard {\n  width: 185px;\n}\n\n.topicCard {\n  width: 340px;\n}\n\n.label {\n  margin-bottom: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "DjMolANpv_CNvL9BlsGr",
	"focusColumn": "GLvezbh6mLnTZg9YH9pa",
	"topicColumn": "Zn_1v6gyuG_SIzTOtKi9",
	"scrollbar": "MXrQ4IFDGZt6Wd_zWHiF",
	"focusCard": "NGwojTNRPzg9q51NnPIe",
	"topicCard": "ODDJgNDFL745Xn6Rk2Y9",
	"label": "zpGytKst18OFPlUpG6Y9"
};
export default ___CSS_LOADER_EXPORT___;
