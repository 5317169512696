import React, { useState } from "react";
import SVG from "react-inlinesvg";
import {
  getComponentId,
  combineClassNames,
  getPrimaryColor,
  getSecondaryColor,
} from "../../../util/util";
import styles from "./selection-card.module.css";

import chevron from "../../../assets/icons/next.svg";
import { ICustomStyle } from "../../../models/style.model";

interface SelectionCardProps {
  id?: string;
  index: number;
  className?: string;
  hasChevron?: boolean;
  disabled?: boolean;
  selected?: boolean;
  children?: JSX.Element | JSX.Element[];
  onClick?: () => void;
  forwardRef?: React.RefObject<HTMLButtonElement> | null;
}

const SelectionCard = ({
  id,
  index,
  className,
  hasChevron,
  disabled,
  selected,
  children,
  onClick,
  forwardRef: ref,
}: SelectionCardProps): JSX.Element => {
  id = getComponentId("selectionCard", id);

  const [startTarget, setStartTarget] = useState<string>();

  const containerStyle = combineClassNames(
    styles.container,
    className,
    selected ? styles.selected : null,
    disabled ? styles.disabled : null
  );

  const handleMouseDown = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    setStartTarget((event as any)?.target?.id);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): void => {
    const endTarget = (event as any).target.id;
    if (
      (endTarget !== startTarget || !startTarget?.length) &&
      !startTarget?.length &&
      selected
    )
      return;
    onClick?.();
  };

  const getButtonStyle = (index: number): ICustomStyle => ({
    "--primary_color": getPrimaryColor(index),
    "--secondary_color": getSecondaryColor(index),
  });

  return (
    <button
      id={`${id}-container`}
      className={containerStyle}
      disabled={disabled}
      onMouseDown={handleMouseDown}
      onClick={(event) => handleClick(event)}
      type="button"
      ref={ref}
      style={getButtonStyle(index)}
    >
      <div id={`${id}-content`} className={styles.content}>
        {children}
      </div>
      {hasChevron && (
        <div id={`${id}-chevronContainer`} className={styles.chevronContainer}>
          <SVG id={`${id}-chevron`} src={chevron} className={styles.chevron} />
        </div>
      )}
    </button>
  );
};

export default SelectionCard;
