import InputRange from "react-input-range";
import { combineClassNames, getComponentId } from "../../../../util/util";

import styles from "./input-slider.module.css";

const DEFAULT_CLASS_NAMES = {
  activeTrack: "input-range__track input-range__track--active",
  disabledInputRange: "input-range input-range--disabled",
  inputRange: "input-range",
  labelContainer: "input-range__label-container",
  maxLabel: "input-range__label input-range__label--max",
  minLabel: "input-range__label input-range__label--min",
  slider: "input-range__slider",
  sliderContainer: "input-range__slider-container",
  track: "input-range__track input-range__track--background",
  valueLabel: "input-range__label input-range__label--value",
};

export interface IInputSliderProps {
  id?: string;
  className?: string;
  minValue: number;
  maxValue: number;
  value: number;
  onChange: (value: number) => void;
  light?: boolean;
  showLabels?: boolean;
  disabled?: boolean;
}

const InputSlider = ({
  maxValue,
  minValue,
  onChange,
  value,
  className,
  id,
  light,
  showLabels,
  disabled,
}: IInputSliderProps): JSX.Element => {
  id = getComponentId("inputSlider", id);

  const containerClassName = combineClassNames(
    className,
    styles.container,
    light ? styles.light : null,
    showLabels ? styles.showLabels : null,
    disabled ? styles.disabled : null
  );

  return (
    <div className={containerClassName} data-testid={`${id}-container`}>
      <InputRange
        data-testid={`${id}-inputRange`}
        maxValue={maxValue}
        minValue={minValue}
        onChange={(value) => onChange(Number(value))}
        value={value}
        disabled={disabled}
        classNames={{
          ...DEFAULT_CLASS_NAMES,
          maxLabel: `${DEFAULT_CLASS_NAMES.maxLabel} ${styles.maxLabel}`,
          minLabel: `${DEFAULT_CLASS_NAMES.minLabel} ${styles.minLabel}`,
          activeTrack: `${DEFAULT_CLASS_NAMES.activeTrack} ${styles.activeTrack}`,
          track: `${DEFAULT_CLASS_NAMES.track} ${styles.track}`,
          slider: `${DEFAULT_CLASS_NAMES.slider} ${styles.slider}`,
          valueLabel: `${DEFAULT_CLASS_NAMES.valueLabel} ${styles.valueLabel}`,
        }}
      />
    </div>
  );
};

export default InputSlider;
