import SVG from "react-inlinesvg";
import { getComponentId, combineClassNames } from "../../../util/util";

import styles from "./icon-header.module.css";

export interface IIconHeaderProps {
  id?: string;
  className?: string;
  iconPath?: string;
  text: string;
}

const IconHeader = ({
  id,
  className,
  iconPath,
  text,
}: IIconHeaderProps): JSX.Element => {
  id = getComponentId("iconHeader", id);

  const containerClassName = combineClassNames(styles.container, className);

  return (
    <div data-testid={`${id}-container`} className={containerClassName}>
      {iconPath && (
        <SVG
          data-testid={`${id}-icon`}
          src={iconPath}
          className={styles.icon}
        />
      )}
      <h2 data-testid={`${id}-header`} className={styles.header}>
        {text}
      </h2>
    </div>
  );
};

export default IconHeader;
