import React from "react";
import { getComponentId, combineClassNames } from "../../../../util/util";

import styles from "./field-label.module.css";

interface IProps {
  id?: string;
  label: string;
  htmlFor?: string;
  className?: string;
}

const FieldLabel: React.FC<IProps> = ({ id, label, htmlFor, className }) => {
  id = getComponentId("fielLabel", id);

  const labelClassName = combineClassNames(className, styles.fieldLabel);

  return (
    <label data-testid={id} className={labelClassName} htmlFor={htmlFor}>
      {label}
    </label>
  );
};

export default FieldLabel;
