// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rZ7a7r990nZdK0o1XvGO {\n  display: grid;\n  grid-template-rows: repeat(3, auto);\n  grid-template-columns: auto max-content;\n  grid-gap: 8px;\n}\n\n.ofi98v1hkQekhH55zJ7W {\n  grid-column: 1;\n  font-size: 16px;\n  font-weight: normal;\n  font-weight: 600;\n}\n\n.KGfH0364gdL6qlfuZ_AB {\n  grid-row: 2;\n}\n\n.gkFNxCbkCNSoLBrfNfqG {\n  grid-row: 2;\n  font-size: 18px;\n  font-weight: 600;\n  color: #2c2c2c;\n  place-self: center;\n  margin: 0;\n}\n\n.VE9PZuW0byluA5hatkaT {\n  grid-row: 2;\n  place-self: center;\n  margin-top: 6px;\n}\n\n.ceAtKF3c8vmqA8v6ZQdy {\n  grid-row: 3;\n  place-self: end;\n  max-width: 100%;\n  align-self: flex-end;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/inputs/select-input/select-input.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mCAAmC;EACnC,uCAAuC;EACvC,aAAa;AACf;;AAEA;EACE,cAAc;EACd,eAAe;EACf,mBAAmB;EACnB,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,eAAe;EACf,gBAAgB;EAChB,cAAc;EACd,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,eAAe;EACf,eAAe;EACf,oBAAoB;EACpB,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;AACzB","sourcesContent":[".container {\n  display: grid;\n  grid-template-rows: repeat(3, auto);\n  grid-template-columns: auto max-content;\n  grid-gap: 8px;\n}\n\n.label {\n  grid-column: 1;\n  font-size: 16px;\n  font-weight: normal;\n  font-weight: 600;\n}\n\n.select {\n  grid-row: 2;\n}\n\n.suffix {\n  grid-row: 2;\n  font-size: 18px;\n  font-weight: 600;\n  color: #2c2c2c;\n  place-self: center;\n  margin: 0;\n}\n\n.hint {\n  grid-row: 2;\n  place-self: center;\n  margin-top: 6px;\n}\n\n.description {\n  grid-row: 3;\n  place-self: end;\n  max-width: 100%;\n  align-self: flex-end;\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "rZ7a7r990nZdK0o1XvGO",
	"label": "ofi98v1hkQekhH55zJ7W",
	"select": "KGfH0364gdL6qlfuZ_AB",
	"suffix": "gkFNxCbkCNSoLBrfNfqG",
	"hint": "VE9PZuW0byluA5hatkaT",
	"description": "ceAtKF3c8vmqA8v6ZQdy"
};
export default ___CSS_LOADER_EXPORT___;
