import { Link } from "react-router-dom";
import image from "../../assets/components/brainix-header/header-logo-left.png";

import styles from "./brainix-header.module.css";

interface IBrainixHeaderProps {
  mainAreaImagePath?: string;
  logoRightImagePath?: string;
}

const BrainixHeader: React.FC<IBrainixHeaderProps> = ({
  mainAreaImagePath = null,
  logoRightImagePath = null,
}) => {
  const id = "brainixHeader";
  return (
    <header id={`${id}-container`} className={styles.header}>
      <Link id={`${id}-link`} className={styles.clickableArea} to="/home">
        <img
          id={`${id}-left-logo`}
          src={image}
          alt=""
          className={styles.logoLeft}
        />
      </Link>
      {mainAreaImagePath && (
        <img
          id={`${id}-main-area`}
          src={mainAreaImagePath}
          alt=""
          className={styles.mainArea}
        />
      )}
      {logoRightImagePath && (
        <img
          id={`${id}-right-logo`}
          src={logoRightImagePath}
          alt=""
          className={styles.logoRight}
        />
      )}
    </header>
  );
};

export default BrainixHeader;
