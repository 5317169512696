import { Popup } from "semantic-ui-react";
import SVG from "react-inlinesvg";
import icon from "../../../assets/components/hint/info.svg";

import styles from "./hint.module.css";
import { getComponentId, combineClassNames } from "../../../util/util";

export interface IHintProps {
  id?: string;
  className?: string;
  on?: "hover" | "click" | "focus";
  content: string | JSX.Element;
}

const Hint = ({ id, className, on, content }: IHintProps): JSX.Element => {
  id = getComponentId("hint", id);

  const containerClassName = combineClassNames(className, styles.container);

  return (
    <div className={containerClassName} data-testid={`${id}-container`}>
      <Popup
        trigger={<SVG src={icon} />}
        on={on}
        content={content}
        position="top center"
      />
    </div>
  );
};

export default Hint;
