import React, { ButtonHTMLAttributes, useEffect } from "react";
import SVG from "react-inlinesvg";

import styles from "./primary-button.module.css";

interface IProps {
  id?: string;
  buttonText: string;
  type: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  buttonIcon: string;
  disabled?: boolean;
  className?: string;
  onClick?: (e: React.MouseEvent) => void;
  buttonIconWidth?: number;
  buttonIconHeight?: number;
  isFrameButton?: boolean;
  seconds?: number;
  buttonTitle?: any;
}

const PrimaryButton: React.FC<IProps> = ({
  id,
  buttonText,
  type,
  buttonIcon,
  disabled,
  className,
  onClick,
  buttonIconWidth = 20,
  buttonIconHeight = 20,
  isFrameButton,
  seconds = null,
  buttonTitle,
}) => {
  const componentId = `${id}-primaryButton`;
  const buttonRef = React.useRef<HTMLButtonElement>(null);
  const primaryButtonStyle = [styles.primaryButton];
  const iconContainerStyle = [styles.iconContainer];

  const [counter, setCounter] = React.useState<number | null>(seconds);
  const showTimer = counter != null && counter > -1;

  useEffect(() => {
    let timer: any;
    if (showTimer) {
      timer = setTimeout(() => setCounter(counter! - 1), 1000);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [counter, showTimer]);

  if (isFrameButton) {
    primaryButtonStyle.push(styles.frameColor);
  }

  if (className) {
    primaryButtonStyle.push(className);
  }

  return (
    <div
      id={`${componentId}-container`}
      className={styles.primaryButtonContainer}
      onClick={(e) => e.stopPropagation()}
    >
      {showTimer && (
        <span id={`${componentId}-timer-text`} className={styles.timer}>
          {counter} sec.
        </span>
      )}
      <button
        id={`${componentId}-button`}
        disabled={disabled || (counter != null && counter > 0)}
        className={primaryButtonStyle.join(" ")}
        type={type}
        ref={buttonRef}
        title={buttonTitle}
        onClick={onClick}
      >
        {buttonText}
      </button>
      <span
        id={`${componentId}-icon-container`}
        className={iconContainerStyle.join(" ")}
        onClick={(e) => {
          e.stopPropagation();
          if (!disabled) {
            buttonRef.current?.click();
          }
        }}
      >
        <SVG
          id={`${componentId}-icon`}
          src={buttonIcon}
          width={buttonIconWidth}
          height={buttonIconHeight}
          className={styles.icon}
        />
      </span>
    </div>
  );
};

export default PrimaryButton;
