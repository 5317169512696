import { DropdownItemProps, Select } from "semantic-ui-react";
import SVG from "react-inlinesvg";
import FieldLabel from "../field-label/field-label.component";

import styles from "./select-input.module.css";
import { getComponentId, combineClassNames } from "../../../../util/util";
import Hint from "../../hint/hint.component";
import icon from "../../../../assets/icons/chevron-down.svg";

export interface ISelectInputProps {
  id?: string;
  label?: string;
  options: Array<DropdownItemProps>;
  defaultValue?: string;
  placeholder?: string;
  className?: string;
  suffix?: string;
  hint?: JSX.Element | string;
  description?: string;
  onChange?: (id: string) => void;
}

const SelectInput = ({
  id,
  label,
  options,
  defaultValue,
  placeholder,
  className,
  description,
  hint,
  suffix,
  onChange,
}: ISelectInputProps): JSX.Element => {
  id = getComponentId("selectInput", id);

  const containerClassName = combineClassNames(styles.container, className);

  return (
    <div data-testid={`${id}-container`} className={containerClassName}>
      {label && <FieldLabel id={id} label={label} className={styles.label} />}
      <Select
        defaultValue={defaultValue}
        placeholder={placeholder}
        options={options}
        icon={<SVG src={icon} />}
        className={styles.select}
        onChange={(_, data) => onChange?.(data.value?.toString() ?? "")}
      />
      {suffix && (
        <p
          id={`${id}-suffix`}
          data-testid={`${id}-suffix`}
          className={styles.suffix}
        >
          {suffix}
        </p>
      )}
      {hint && (
        <Hint
          content={hint}
          on="hover"
          className={styles.hint}
          data-testid={`${id}-hint`}
        />
      )}
      {description && (
        <p
          id={`${id}-description`}
          data-testid={`${id}-description`}
          className={styles.description}
        >
          {description}
        </p>
      )}
    </div>
  );
};

export default SelectInput;
