// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Nyw9LulyqaP2tf21dnxx {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: flex-start;\n  min-width: 0;\n  overflow: hidden;\n}\n\n._gxbdbQCQBQfr4NCmFwc {\n  width: 15px;\n  height: 15px;\n  min-width: 15px;\n  min-height: 15px;\n  position: relative;\n  top: 5px;\n  fill: var(--current-subject-medium-color);\n}\n\n.jlJAS0nNnmGLMBAAkuDL {\n  display: inline-block;\n  font-family: \"Source Sans Pro\";\n  font-size: 16px;\n  line-height: 21px;\n  margin: 0;\n  margin-left: 12px;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/description/description.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,2BAA2B;EAC3B,uBAAuB;EACvB,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,QAAQ;EACR,yCAAyC;AAC3C;;AAEA;EACE,qBAAqB;EACrB,8BAA8B;EAC9B,eAAe;EACf,iBAAiB;EACjB,SAAS;EACT,iBAAiB;AACnB","sourcesContent":[".container {\n  display: flex;\n  flex-direction: row;\n  justify-content: flex-start;\n  align-items: flex-start;\n  min-width: 0;\n  overflow: hidden;\n}\n\n.icon {\n  width: 15px;\n  height: 15px;\n  min-width: 15px;\n  min-height: 15px;\n  position: relative;\n  top: 5px;\n  fill: var(--current-subject-medium-color);\n}\n\n.description {\n  display: inline-block;\n  font-family: \"Source Sans Pro\";\n  font-size: 16px;\n  line-height: 21px;\n  margin: 0;\n  margin-left: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Nyw9LulyqaP2tf21dnxx",
	"icon": "_gxbdbQCQBQfr4NCmFwc",
	"description": "jlJAS0nNnmGLMBAAkuDL"
};
export default ___CSS_LOADER_EXPORT___;
