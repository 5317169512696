interface RenderProps {
  id?: string;
  children: JSX.Element | Array<JSX.Element>;
}

export interface IConditionalWrapperProps {
  id?: string;
  when?: boolean;
  render: ({ id, children }: RenderProps) => JSX.Element | Array<JSX.Element>;
  children: JSX.Element | Array<JSX.Element>;
}

const ConditionalWrapper = ({
  id,
  when,
  render,
  children,
}: IConditionalWrapperProps): JSX.Element => {
  if (!when) return <>{children}</>;

  if (Array.isArray(children)) return <>{render({ id, children })}</>;

  return render({ id, children }) as JSX.Element;
};

export default ConditionalWrapper;
