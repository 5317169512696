import React, { ButtonHTMLAttributes } from "react";
import { capitalize } from "lodash";

import styles from "./secondary-button.module.css";

interface IProps {
  id?: string;
  buttonText: string;
  isCapitalized?: boolean;
  type: ButtonHTMLAttributes<HTMLButtonElement>["type"];
  disabled?: boolean;
  className?: any;
  onClick?: (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
  isFrameButton?: boolean;
}

const SecondaryButton: React.FC<IProps> = ({
  id,
  disabled,
  className,
  onClick,
  type,
  buttonText,
  isCapitalized = true,
  isFrameButton,
}) => {
  const componentId = `${id}-secondaryButton`;
  const secondaryButtonStyle = [styles.secondaryButton];

  if (isFrameButton) {
    secondaryButtonStyle.push(styles.frameColor);
  }

  if (className) {
    secondaryButtonStyle.push(className);
  }

  return (
    <input
      id={`${componentId}-button`}
      value={`${isCapitalized ? capitalize(buttonText) : buttonText}`}
      disabled={disabled}
      className={secondaryButtonStyle.join(" ")}
      onClick={onClick}
      type={type}
    />
  );
};

export default SecondaryButton;
