import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  ICurriculum,
  ISelectableCategory,
  ISelectableSkill,
} from "../../models/models";

export interface IConfiguratorState {
  competence: null | string;
  curricula: Array<ICurriculum>;
  selectedCurriculumId: string | null;
  categories: Array<ISelectableCategory>;
  skillFilter: string | null;
}

const initialState: IConfiguratorState = {
  competence: null,
  curricula: [],
  selectedCurriculumId: null,
  categories: [],
  skillFilter: null,
};

export const configuratorSlice = createSlice({
  name: "configurator",
  initialState,
  reducers: {
    setCompetence: (
      state,
      action: PayloadAction<IConfiguratorState["competence"]>
    ) => {
      state.competence = action.payload;
    },
    setCurricula: (state, action: PayloadAction<Array<ICurriculum>>) => {
      state.curricula = action.payload;
    },
    setSelectedCurriculumId: (state, action: PayloadAction<string | null>) => {
      state.selectedCurriculumId = action.payload;
    },
    setCategories: (state, action: PayloadAction<ICurriculum>) => {
      state.categories = action.payload.Categories.map((category) => ({
        categoryId: category.CategoryId,
        name: category.Name,
        selected: false,
        skills: category.Skills.map(
          (skill): ISelectableSkill => ({
            skillId: skill.SkillId,
            name: skill.Name,
            weight: 0,
            selected: false,
          })
        ),
      }));
    },
    updateCategories: (
      state,
      action: PayloadAction<Array<ISelectableCategory>>
    ) => {
      state.categories = action.payload;
    },
    selectCategory: (
      state,
      action: PayloadAction<ISelectableCategory["categoryId"]>
    ) => {
      state.categories.map((category) => {
        if (category.categoryId === action.payload)
          category.selected = !category.selected;
        return category;
      });
    },
    selectSkill: (
      state,
      action: PayloadAction<ISelectableSkill["skillId"]>
    ) => {
      state.categories.map((category) => {
        category.skills.map((skill) => {
          if (skill.skillId === action.payload) {
            if (skill.selected) skill.weight = 0;
            skill.selected = !skill.selected;
          }
          return skill;
        });
        return category;
      });
    },
    changeSkill: (
      state,
      action: PayloadAction<{
        skillId: ISelectableSkill["skillId"];
        value: number;
      }>
    ) => {
      state.categories.map((category) => {
        category.skills.map((skill) => {
          if (skill.skillId === action.payload.skillId) {
            skill.weight = action.payload.value;
          }
          return skill;
        });
        return category;
      });
    },
    filterSkill: (state, action: PayloadAction<string | null>) => {
      state.skillFilter = action.payload;
    },
  },
});

export const {
  setCompetence,
  setCurricula,
  setSelectedCurriculumId,
  setCategories,
  updateCategories,
  selectCategory,
  selectSkill,
  changeSkill,
  filterSkill,
} = configuratorSlice.actions;

export default configuratorSlice.reducer;
