import SyntaxHighlighter from "react-syntax-highlighter";
import { a11yLight as style } from "react-syntax-highlighter/dist/esm/styles/hljs";
import styles from "./code-preview.module.css";
import Scrollbar from "react-scrollbars-custom";
import { getComponentId, combineClassNames } from "../../util/util";
import SecondaryButton from "../common/inputs/buttons/secondary-button/secondary-button.component";
import { useState } from "react";

export interface ICodePreview {
  id?: string;
  className?: string;
  snippets: Array<{ title: string; code: string }>;
}

const CodePreview = ({
  id,
  className,
  snippets,
}: ICodePreview): JSX.Element => {
  id = getComponentId("codePreview", id);

  const [wasCopied, setWasCopied] = useState<boolean | number>(false);

  const containerClassName = combineClassNames(className, styles.container);

  const handleCopyClick = (text: string, index: number): void => {
    navigator.clipboard.writeText(text);
    setWasCopied(index);
    setTimeout(() => setWasCopied(false), 1000);
  };

  return (
    <div data-testid={`${id}-container`} className={containerClassName}>
      {snippets.map((snippet, index) => (
        <div className={styles.sectionContainer} key={index}>
          <h2>{snippet.title}</h2>
          <div className={styles.section}>
            <Scrollbar style={{ width: "100%", height: "100%" }}>
              <SyntaxHighlighter
                showLineNumbers
                language="csharp"
                style={style}
              >
                {snippet.code}
              </SyntaxHighlighter>
            </Scrollbar>
            <SecondaryButton
              onClick={() => handleCopyClick(snippet.code, index)}
              className={`${styles.copyButton} ${
                wasCopied === index ? styles.copied : ""
              }`}
              buttonText="Kopieren"
              type="button"
            />
          </div>
        </div>
      ))}
    </div>
  );
};

export default CodePreview;
