import SVG from "react-inlinesvg";
import { combineClassNames, getComponentId } from "../../../util/util";
import styles from "./description.module.css";
import icon from "../../../assets/components/description/information-text.svg";

export interface IDescriptionProps {
  id?: string;
  className?: string;
}

const Description: React.FC<IDescriptionProps> = ({
  id,
  className,
  children,
}) => {
  id = getComponentId("description", id);

  const containerClassName = combineClassNames(styles.container, className);

  return (
    <div data-testid={`${id}-container`} className={containerClassName}>
      <SVG id={`${id}-icon`} src={icon} className={styles.icon} />
      <p data-testid={`${id}-description`} className={styles.description}>
        {children}
      </p>
    </div>
  );
};

export default Description;
