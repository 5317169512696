// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tozLC2FoNhCMG5GIFlTz {\n  position: relative;\n}\n\n.tozLC2FoNhCMG5GIFlTz:not(:first-child) {\n  padding-top: 30px;\n}\n\n.tozLC2FoNhCMG5GIFlTz:not(:last-child) {\n  padding-bottom: 30px;\n}\n\n.tozLC2FoNhCMG5GIFlTz:not(:last-child)::after {\n  content: \"\";\n  position: absolute;\n  height: 2px;\n  background-color: white;\n  bottom: 0;\n  left: 0;\n  right: 0;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/form/form-section/form-section.module.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,oBAAoB;AACtB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,WAAW;EACX,uBAAuB;EACvB,SAAS;EACT,OAAO;EACP,QAAQ;AACV","sourcesContent":[".container {\n  position: relative;\n}\n\n.container:not(:first-child) {\n  padding-top: 30px;\n}\n\n.container:not(:last-child) {\n  padding-bottom: 30px;\n}\n\n.container:not(:last-child)::after {\n  content: \"\";\n  position: absolute;\n  height: 2px;\n  background-color: white;\n  bottom: 0;\n  left: 0;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "tozLC2FoNhCMG5GIFlTz"
};
export default ___CSS_LOADER_EXPORT___;
