// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".aHJ8OlztwHqh9iX3PeLl {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  text-align: left;\n  font-family: Source Sans Pro;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 16px;\n  color: rgba(44, 44, 44, 1);\n  display: block;\n}\n", "",{"version":3,"sources":["webpack://./src/components/common/inputs/field-label/field-label.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,uBAAuB;EACvB,gBAAgB;EAChB,4BAA4B;EAC5B,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;EACf,0BAA0B;EAC1B,cAAc;AAChB","sourcesContent":[".fieldLabel {\n  white-space: nowrap;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  text-align: left;\n  font-family: Source Sans Pro;\n  font-style: normal;\n  font-weight: 600;\n  font-size: 16px;\n  color: rgba(44, 44, 44, 1);\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fieldLabel": "aHJ8OlztwHqh9iX3PeLl"
};
export default ___CSS_LOADER_EXPORT___;
