import { useState } from "react";
import styles from "./configurator-page.module.css";
import BrainixHeader from "../../components/brainix-header/brainix-header.component";
import CodePreview from "../../components/code-preview/code-preview.component";
import AlertView from "../../components/common/alert-view/alert-view.component";
import Description from "../../components/common/description/description.component";
import IconHeader from "../../components/common/icon-header/icon-header.component";
import Configurator from "../../features/configurator/configurator.component";
import { useSelector } from "react-redux";
import { IState } from "../../redux/store";
import { getCategorizationCode } from "../../templates/categorization";
import { ISelectableCategory, IWeigtedSkill } from "../../models/models";

import logo from "../../assets/components/brainix-header/header-logo-right.png";
import settings from "../../assets/icons/settings.svg";
import { getTotalWeight } from "../../../src/util/util";

const ConfiguratorPage = (): JSX.Element => {
  const componentId = "configurator";
  const [isCodeMessageVisible, setIsCodeMessageVisible] = useState<boolean>();

  const [categorizationCode, setCategorizationCode] = useState<null | string>(
    null
  );

  const categories = useSelector(
    (state: IState) => state.configurator.categories
  );

  const competence = useSelector(
    (state: IState) => state.configurator.competence
  );

  const getWeigtedSkills = (
    categories: Array<ISelectableCategory>,
    totalWeight: number
  ): Array<IWeigtedSkill> =>
    categories
      .filter((category) => category.selected)
      .map((category) => category.skills)
      .flat()
      .filter((skill) => skill.selected && skill.weight > 0)
      .map((skill) => ({
        skillId: skill.skillId,
        weight: skill.weight / totalWeight,
      }));

  const totalWeight: number = useSelector((state: IState) =>
    getTotalWeight(state.configurator.categories)
  );

  const isSubmitDisabled =
    !competence ||
    !categories.some(
      (category) =>
        category.selected &&
        category.skills.some((skill) => skill.selected && skill.weight)
    );

  const handleConfirmClick = () => {
    if (isSubmitDisabled) return;

    setCategorizationCode(
      getCategorizationCode({
        competence,
        weigtedSkills: getWeigtedSkills(categories, totalWeight),
      })
    );
    setIsCodeMessageVisible(true);
  };

  return (
    <div className={styles.container}>
      <BrainixHeader logoRightImagePath={logo} />
      <div className={styles.safeArea}>
        <IconHeader
          text="Aufgabenspezifikation"
          iconPath={settings}
          className={styles.iconHeader}
        />
        <Description className={styles.description}>
          <span>
            Damit wir Schülerinnen und Schülern personalisiert und angemessen
            ihrem Lernstand Aufgaben zuweisen können, wird hier ein spezifischer
            Code für jeden <b>Performance Task</b> erstellt. Kopiere diesen auf
            die erste Zeichenfläche deines Performance Tasks.
          </span>
        </Description>
        <Configurator
          onConfirm={handleConfirmClick}
          isSubmitDisabled={isSubmitDisabled}
        />
        <AlertView
          show={isCodeMessageVisible}
          leftButtonLabel="Schließen"
          onLeftButtonClick={() => setIsCodeMessageVisible(false)}
          hasBackdrop
          id={componentId}
        >
          <CodePreview
            id={componentId}
            snippets={[
              { title: "Klassifizierung", code: categorizationCode ?? "" },
            ]}
          />
        </AlertView>
      </div>
    </div>
  );
};

export default ConfiguratorPage;
